$(function () {
    const loginForm = $('#login-form');
    const registerForm = $('#register-form');
    const registerWithCodeForm = $('#form-validation');

    const authType = $('input[name="authType"]');
    const authTypeLoginInput = $('#auth-type-login');
    const authInfoLogin = $('.auth-info-login');
    const authTypeRegisterInput = $('#auth-type-register');
    const authTypeRegisterWithCodeInput = $('#auth-type-register-with-code');
    const registerTof = $('.register-tof');
    const authTypeItems = $('.auth-type-item');
    const authActionBtns = $('.auth-action .btn');
    const authActionContainers = $('.auth-action');
    const authInfoUca = $('.auth-info-uca');
    const loginActionItem = $('.auth-action-login');
    const schoolSelectContainer = $('#school-select-container');
    const schoolListBackBtn = $('#school-list-back');
    const schoolList = $('.school-list');
    const ulInfo = $('.ul-info');
    const authActionOr = $('.auth-action-or');
    const authInformations = $('.auth-information');
    const authCreateAccountContainer = $('#auth-action-create-account').closest('.auth-info-login');
    const schoolInfo = $('.school-info');
    const authActionCode = $('.auth-action-code');

    function displayLoginForm(onlyLoginForm) {

        if (onlyLoginForm === true) {
            authActionContainers.find('.auth-action-item').addClass('hidden');
            authActionOr.addClass('hidden');
            authActionCode.addClass('hidden');
            authActionContainers.removeClass('hidden');
        }

        registerForm.addClass('hidden');
        registerWithCodeForm.addClass('hidden');
        authTypeLoginInput.attr('checked', 'checked');
        loginForm.fadeIn().removeClass('hidden');
        loginActionItem.addClass('hidden');
        const loginUsernameInput = $('#username');
        if (loginUsernameInput.length) {
            loginUsernameInput.focus();
        }
        
    }

    function displayAuthForm(id) {
        loginActionItem.removeClass('hidden');
        authActionOr.removeClass('hidden');
        registerTof.show();

        switch (id){
            case 'auth-type-login':
                displayLoginForm(false);
                registerTof.hide();
                break;
            case 'auth-type-register':
                loginForm.addClass('hidden');
                registerWithCodeForm.addClass('hidden');
                authTypeRegisterInput.attr('checked', 'checked');
                registerForm.fadeIn().removeClass('hidden');
                const registerEmailInput = $('#b_to_c_register_email');
                if (registerEmailInput.length) {
                    registerEmailInput.focus();
                }
                break;
            case 'auth-type-register-with-code':
                loginForm.addClass('hidden');
                registerForm.addClass('hidden');
                authTypeRegisterWithCodeInput.attr('checked', 'checked');
                registerWithCodeForm.fadeIn().removeClass('hidden');
                const registerWithCodeEmailInput = $('#registration_email');
                if (registerWithCodeEmailInput.length) {
                    registerWithCodeEmailInput.focus();
                }
                break;
            default: return;
        }
    }

    authType.on('change', (event) => {
        let selected = event.target;
        if (selected) {
            selected = $(selected);
            authType.removeAttr('checked');
            selected.attr('checked', 'checked');

            const id = selected.attr('id');
            // const loginSelected = id === 'auth-type-login';
            displayAuthForm(id);
        }
    });

    //Password visibility on/off
    function enablePasswordVisibility() {
        const passwordVisibilityOn = document.querySelector('#password-visibility-on');
        const passwordVisibilityOff = document.querySelector('#password-visibility-off');
        const loginPasswordField = document.querySelector('#password');

        const registerPasswordVisibilityOn = document.querySelector('#register-password-visibility-on');
        const registerPasswordVisibilityOff = document.querySelector('#register-password-visibility-off');
        const registerPasswordField = document.querySelector('#b_to_c_register_plainPassword');

        //---------- FOR LOGIN ------------//
        passwordVisibilityOn.addEventListener('click', function () {
            loginPasswordField.setAttribute('type', 'text');
            passwordVisibilityOn.classList.add('hidden');
            passwordVisibilityOff.classList.remove('hidden');
        });

        passwordVisibilityOff.addEventListener('click', function () {
            loginPasswordField.setAttribute('type', 'password');
            passwordVisibilityOn.classList.remove('hidden');
            passwordVisibilityOff.classList.add('hidden');
        });

        //---------- FOR REGISTER ------------//
        registerPasswordVisibilityOn.addEventListener('click', function () {
            registerPasswordField.setAttribute('type', 'text');
            registerPasswordVisibilityOn.classList.add('hidden');
            registerPasswordVisibilityOff.classList.remove('hidden');
        });

        registerPasswordVisibilityOff.addEventListener('click', function () {
            registerPasswordField.setAttribute('type', 'password');
            registerPasswordVisibilityOn.classList.remove('hidden');
            registerPasswordVisibilityOff.classList.add('hidden');
        });
    }

    function clearRegisterFormErrors() {
        registerForm.find('.has-error').removeClass('has-error');
        registerForm.find('.help-block').remove();
    }

    function submitRegister(e) {
        e.preventDefault();

        //Clear previous errors
        clearRegisterFormErrors();

        const form = $(e.currentTarget);
        const formValues = form.serializeArray();
        const plainPasswordFieldName = 'b_to_c_register[plainPassword]';
        const plainPasswordFieldId = 'b_to_c_register_plainPassword';
        const plainPassword = (formValues.find(item => item.name === plainPasswordFieldName) || {value: ''}).value;

        const request = $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
        });

        request.done(function(response) {
            //Redirect to homepage
            if (window.Routing) {
                const url = Routing.generate('index');
                if (url) {
                    console.log(url)
                    window.location.href = url;
                } else {
                    window.location.href = '/';
                }
            } else {
                window.location.href = '/';
            }
        });

        request.fail(function(jqXHR) {
            const { responseText, status } = jqXHR;
            if (status === 400) {
                //Display form with errors
                registerForm.replaceWith($(responseText).removeClass('hidden'));

                registerForm = $(document).find('#register-form');
                registerForm.on('submit', submitRegister);

                //Set plainPassword value
                registerForm.find(`#${plainPasswordFieldId}`).val(plainPassword);


                enablePasswordVisibility();
            }

        });
    }

    registerForm.on('submit', submitRegister);

    displayLoginForm(true);

    enablePasswordVisibility();

    function hideFormAndLabels() {
        loginForm.addClass('hidden');
        registerForm.addClass('hidden');
        registerWithCodeForm.addClass('hidden');
        authType.attr('checked', false);
        authTypeItems.addClass('hidden');
        authInfoUca.addClass('hidden');
        authInfoLogin.addClass('hidden');
        schoolListBackBtn.addClass('hidden');
        authInformations.find('p').addClass('hidden');
        authActionCode.addClass('hidden');

        registerTof.hide();
    }

    authTypeItems.addClass('hidden');

    schoolListBackBtn.on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        hideFormAndLabels();
        authActionOr.removeClass('hidden');
        authActionContainers.removeClass('hidden');
        authActionContainers.find('.auth-action-item').removeClass('hidden');
        schoolSelectContainer.addClass('hidden');
        authCreateAccountContainer.addClass('hidden');
        registerForm.addClass('hidden');
    });

    authActionBtns.on('click', function(){
      
        const id = $(this).attr('id');
        
        if (!id || id === 'auth-action-create-account-code') {
            return;
        }

        hideFormAndLabels();

        const schoolArray = $('.schoolpicker option').map(function () {
                const img = $(this).data('thumbnail');
                const authType = $(this).data('auth-type');
                const text = $(this).text();
                const value = $(this).val();
                return `<li><img src="${img}" alt="" value="${value}" data-auth-type="${authType}"/><span>${text}</span></li>`;
            }).get();

        schoolSelectContainer.addClass('hidden');

        if (id === 'auth-action-login') {
            displayAuthForm('auth-type-login');
        } 
        
        else if (id === 'auth-action-signin') {
            authActionContainers.addClass('hidden');
            schoolSelectContainer.removeClass('hidden');
            schoolList.removeClass('hidden');
            schoolListBackBtn.removeClass('hidden');
            schoolInfo.removeClass('hidden');
            authActionCode.removeClass('hidden');
            $('.auth-action-or-list').removeClass('hidden');

            registerTof.show();
            
            const btnSelect = $('.btn-select');
         
            btnSelect.html(schoolArray[0]);
            btnSelect.attr('value', '');

        }
    })

    $('.auth-modal').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();


        const authModal = $('#auth-modal');
        const loginFormInfos = authModal.find('.login-form-info');
        const dataFor = $(this).attr('data-for');

        loginFormInfos.addClass('hidden');


        loginFormInfos.each(function(){
            const dataInfoFor = $(this).attr('data-info-for');

            if (dataInfoFor === dataFor) {
                $(this).removeClass('hidden');
            }
        })

        authModal.modal('show');
    });

    $('#auth-action-create-account').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        authActionContainers.addClass('hidden');
        $(this).closest('.auth-info-login').addClass('hidden');
        displayAuthForm('auth-type-register');
    })



    $('.school-list li').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.school-list li').removeClass('active');
        $(this).addClass('active');

        schoolList.addClass('hidden');
      
        const authTypeFor = $(this).data('auth-type');
        const schoolSlug = $(this).data('slug');
        const withCode = $(this).data('with-code');

        let displayCreateForm = true;

        authCreateAccountContainer.addClass('hidden');
      
        // Toggle the .school-list-container
        $('.school-list-container').toggle();

        // Hide form and labels
        hideFormAndLabels();


        if (schoolSlug === 'universite-de-lorraine') {
            ulInfo.removeClass('hidden');
        }

        schoolListBackBtn.removeClass('hidden');

        const authTypeForArray = authTypeFor.split(',');

        if (parseInt(withCode) === 1) {
            let modalTarget = 'modal-register-with-code';
            
            const authType = $(this).data('auth-type');


            if (authType.includes('auth-type-register-with-code-uca')) {
                modalTarget = 'modal-register-with-code-uca';
            }

            const authModal = $('.auth-info-register-with-code .auth-modal');

            authModal.attr('data-for', modalTarget);

            $('.auth-info-second').addClass('hidden');

            $('.with-code').removeClass('hidden');
            displayAuthForm('auth-type-register-with-code');
        } else {

            const others = (parseInt($(this).data('value')) === -1);
            schoolListBackBtn.removeClass('hidden');

            if (others) {
                displayAuthForm('auth-type-register');
                displayCreateForm = false;
            } 
            else {
                $('.without-code').removeClass('hidden');
                displayLoginForm(true);
                registerTof.show();
            }
        }
        if (displayCreateForm) {
            authCreateAccountContainer.removeClass('hidden');
        } 
        return;

       
        authTypeForArray.forEach(function (item) {

            if (item !== 'auth-type-register') {
                authInfoLogin.removeClass('hidden');
            }

            if (item !== 'auth-type-login') {
                let realItem = item;

                if (item.includes('auth-type-register-with-code')) {
                    let modalTarget = 'modal-register-with-code'
                    realItem = 'auth-type-register-with-code';

                    if (item === 'auth-type-register-with-code-uca')
                        modalTarget = 'modal-register-with-code-uca';

                    const authModal = $('.auth-info-register-with-code .auth-modal');

                    authModal.attr('data-for', modalTarget);

                    $('.auth-info-second').addClass('hidden');
                }
                $('.with-code').removeClass('hidden');

                displayAuthForm(realItem);
            } else {
                schoolListBackBtn.removeClass('hidden');
                $('.without-code').removeClass('hidden');
                displayLoginForm(true);
            }
        });
    });


    $(document).on('click', '#auth-action-create-account-code', function(e) {
        e.preventDefault();
        e.stopPropagation();

        window.location.href = $(this).data('url');
    })

});